import React, { ReactElement } from 'react';

const PageNotFound = (): ReactElement => {
  return (
    <div>
      <h1>Ooops! Page not found.</h1>
      <p>Error 404</p>
    </div>
  );
};

export default PageNotFound;
